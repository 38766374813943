import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  TextField,
  TextFieldProps
} from "$Imports/MaterialUIComponents";

//#region Internal

interface IPhoneFormattingResult {
  phone: string;
  full: string;
}

function formatFull(phone: string, extension: string): string {
  return !extension
    ? phone
    : `${phone} x${extension}`;
}

function sanitizePhone(phone: string, extension: string): IPhoneFormattingResult {
  const result: IPhoneFormattingResult = {
    phone: "",
    full: ""
  };

  let sanitized = phone.replace(/[^\d\-]/g, "")
  if (sanitized.length > 12) {
    sanitized = sanitized.substring(0, 12);
  }

  let digitsOnly = sanitized.replace(/\D/g, "");
  if (digitsOnly.length > 10) {
    digitsOnly = digitsOnly.substring(0, 10);
  }

  if (digitsOnly.length === 10) {
    result.phone = `${digitsOnly.substring(0, 3)}-${digitsOnly.substring(3, 6)}-${digitsOnly.substring(6)}`;
  }
  else {
    result.phone = sanitized;
  }

  result.full = formatFull(result.phone, extension);

  return result;
}

//#endregion

interface IPhoneNumberInputFieldState {
  phoneNumber?: string;
  ext?: string;
  phoneNumberError: boolean;
}

interface IPhoneNumberInputFieldBaseProps {
  onPhoneNumberChange: (newValue?: string) => void;
  value?: string;
  captureExt?: boolean;
  className?: string;
}

type IPhoneNumberInputFieldProps = IPhoneNumberInputFieldBaseProps & TextFieldProps;

export class PhoneNumberInputField extends React.Component<IPhoneNumberInputFieldProps, IPhoneNumberInputFieldState> {
  state: IPhoneNumberInputFieldState = {
    phoneNumber: undefined,
    ext: undefined,
    phoneNumberError: false
  }

  componentDidMount() {
    this._splitFullValue(this.props.value ?? "");
  }

  componentDidUpdate(prev: IPhoneNumberInputFieldProps) {
    const { phoneNumberError } = this.state;

    if (prev.value !== this.props.value) {
      this._splitFullValue(this.props.value ?? "");
    }

    if (prev.error !== this.props.error && this.props.error !== phoneNumberError) {
      this.setState({
        phoneNumberError: !!this.props.error
      });
    }
  }

  @bind
  private _splitFullValue(full: string) {
    const {
      phoneNumber,
      ext
    } = this.state;

    const parts = full.split(' x');
    const newPhoneNumber = parts?.[0] ?? ""
    const newExt = parts?.[1] ?? ""

    if (newPhoneNumber !== phoneNumber) {
      this.setState({ phoneNumber: newPhoneNumber });
    }

    if (newExt !== ext) {
      this.setState({ ext: newExt });
    }
  }

  @bind
  private _onPhoneNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {
      phoneNumber,
      ext
    } = this.state;

    const result = sanitizePhone(event.target.value ?? "", ext ?? "");

    if (result.phone !== phoneNumber) {
      this.setState({
        phoneNumber: result.phone
      }, () => {
        this.props.onPhoneNumberChange(result.full);
      });
    }
  }

  @bind
  private _onExtChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {
      phoneNumber,
      ext
    } = this.state;

    const newExt = (event.target.value ?? "")
      .replace(/\D/g, "")
      .substring(0, 5);

    if (newExt !== ext) {
      this.setState({
        ext: newExt
      }, () => {
        const full = formatFull(phoneNumber ?? "", newExt);
        this.props.onPhoneNumberChange(full);
      });
    }
  }

  render() {
    const {
      phoneNumber,
      ext,
      phoneNumberError
    } = this.state;

    const {
      value,
      onPhoneNumberChange,
      ref,
      captureExt,
      className,
      ...passthroughProps
    } = this.props;

    return (
      <div className={className ?? ""}>
        <TextField
          {...passthroughProps}
          style={{ width: "100%" }}
          onChange={this._onPhoneNumberChange}
          value={phoneNumber ?? ""}
          error={phoneNumberError}
        />
        {captureExt &&
          <TextField
            label="Ext"
            style={{ width: "80px" }}
            onChange={this._onExtChange}
            value={ext ?? ""}
          />}
      </div>
    );
  }
}
