import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf
} from "$Shared/imports/Yup";

import {
  Customer,
  CustomerApiFactory,
  CustomerBillingStatusEnum,
  CustomerUser,
  Region
} from "$Generated/api";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

export const CustomerValidationSchema: SchemaOf<NullableOptional<Customer>> = yup.object({
  id: yup.number().notRequired(),
  customerName: yup.string().required("Customer Name is required").max(150, "Max length can not exceed 150 characters"),
  tmcustomerId: yup.string().notRequired().allowEmpty().nullable(),
  contactName: yup.string().notRequired().max(300, "Max length can not exceed 300 characters").nullable(),
  emailAddress: yup.string()
    .when('isCaller', {
      is: true,
      then: yup.string().required("Email is required").email("Invalid email").max(250, "Max length can not exceed 250 characters").nullable(),
      otherwise: yup.string().notRequired().allowEmpty().nullable()
    }),
  address1: yup.string().notRequired().allowEmpty().max(150, "Max length can not exceed 150 characters").nullable(),
  address2: yup.string().notRequired().allowEmpty().max(150, "Max length can not exceed 150 characters").nullable(),
  city: yup.string().notRequired().allowEmpty().max(150, "Max length can not exceed 150 characters").nullable(),
  regionId: yup.number().notRequired(),
  phoneNumber: yup.string().required("Phone Number is required").allowEmpty().phoneNumber("Invalid phone number").nullable(),
  cellNumber: yup.string().notRequired().allowEmpty().phoneNumber("Invalid phone number").nullable(),
  zipPostalCode: yup.string()
    .when("region", (region: Region | undefined | null, schema: any) => {
      return region?.countryId === 1 ? schema.notRequired().allowEmpty().usZipCode().nullable()
      : region?.countryId === 2 ? schema.notRequired().allowEmpty().canadianZipCode().nullable()
      : schema.notRequired().allowEmpty().zipCode().nullable()
    }),
  website: yup.string().notRequired().allowEmpty().nullable().max(150, "Max length can not exceed 150 characters").website("Website must start with http:// or https://"),
  isActive: yup.boolean().notRequired(),
  accountManagerId: yup.number().notRequired().allowNaN().nullable(),
  businessDevManagerId: yup.number().notRequired().allowNaN().nullable(),
  customerSince: yup.date().notRequired().nullable(),
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  isCaller: yup.boolean().notRequired() // this test performs validation for all 3 checkboxes
    .test("isCaller", "${message}", (value: any, testContext: any) => {
      if (!(testContext.parent.isCaller || testContext.parent.isShipper || testContext.parent.isConsignee)) {
        return testContext.createError({ message: "At least one customer type must be selected" });
      }

      return true;
    }),
  isConsignee: yup.boolean().notRequired(),
  isShipper: yup.boolean().notRequired(),
  region: yup.object().notRequired().nullable(),
  accountManager: yup.object().notRequired().nullable(),
  businessDevManager: yup.object().notRequired().nullable(),
  quotes: yup.array().notRequired(),
  addresses: yup.array().notRequired(),
  customerContacts: yup.array().notRequired(),
  customerQuotes: yup.array().notRequired(),
  commodityExclusions: yup.array().notRequired(),
  activities: yup.array().notRequired().nullable(),
  customerHours: yup.array().notRequired().nullable(), // the array param is so openTime/closeTime don't form a cyclic dependency
  hasCustomerPortalAccess: yup.boolean().notRequired(),
  displayAlert: yup.boolean().notRequired(),
  alert: yup.string().notRequired().nullable(),
  customerLoadingInstructions: yup.array().notRequired().nullable(),
  customerSourceId: yup.number().notRequired().nullable(),
  customerSource: yup.object().notRequired().nullable(),
  isProspect: yup.boolean().notRequired(),
  prospectId: yup.number().notRequired().allowNaN().nullable(),
  prospect: yup.object().notRequired().nullable(),
  sourceDetails: yup.string().notRequired().allowEmpty().max(50, "Max length can not exceed 50 characters").nullable(),
  isBillTo: yup.boolean().notRequired(),
  billingStatus: yup.mixed<CustomerBillingStatusEnum>().notRequired().nullable(),
  creditLimit: yup.number().notRequired().nullable(true),
  dueDays: yup.number().notRequired().nullable(true),
  estAvgMonthlyFreightBills: yup.number().notRequired().transform((value: any) => value || undefined),
  estAvgRevenuePerFreightBill: yup.number().notRequired().transform((value: any) => value || undefined),
  hasBillingAddress: yup.boolean().notRequired(),
  billingAddress1: yup.string().notRequired().nullable(),
  billingAddress2: yup.string().notRequired().nullable(),
  billingCity: yup.string().notRequired().nullable(),
  billingRegionId: yup.number().notRequired().nullable(),
  billingZipPostalCode: yup.string().notRequired().nullable(),
  billingRegion: yup.object().notRequired().nullable(),
  oneTimeTmSync: yup.boolean().notRequired()
});

export const CustomerAddressRequiredValidationSchema: SchemaOf<NullableOptional<Customer>> = CustomerValidationSchema.concat(
  yup.object({
    address1: yup.string().required("Address is required").max(150, "Max length can not exceed 150 characters"),
    city: yup.string().required("City is required").max(150, "Max length can not exceed 150 characters"),
    regionId: yup.number().required("Region is required"),
    region: yup.object().notRequired().nullable(),
    zipPostalCode: yup.string()
      .when("region", (region: Region | undefined | null, schema: any) => {
        return region?.countryId === 1 ? schema.required("Postal Code is required").usZipCode() 
          : region?.countryId === 2 ? schema.required("Postal Code is required").canadianZipCode()
          : schema.required("Postal Code is required").zipCode()
      })
  }));

interface ICustomerServiceState {
  currentCustomer: Customer | undefined;
  customerFetchResults: IAjaxState<Customer>;
  customerUsersFetchResults: IAjaxState<CustomerUser[]>;
}

const InjectedPropName = "customerService";

const initialState = {
  currentCustomer: undefined,
  customerFetchResults: managedAjaxUtil.createInitialState(),
  customerUsersFetchResults: managedAjaxUtil.createInitialState()
} as ICustomerServiceState;

class CustomerFreezerService extends FreezerService<ICustomerServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public async getCustomerByTruckMateId(forceUpdate: boolean = false) {

    const {
      customerFetchResults
    } = this.freezer.get();

    if (customerFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "customerFetchResults",
      params: {
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomersTmCustomerIdGet(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch customer.");
      },
      onOk: (data: Customer) => {
        this.freezer.get().set({
          currentCustomer: data
        });
      }
    });
  }

  public async getCustomerUsersByTruckMateId(forceUpdate: boolean = false) {

    const {
      customerUsersFetchResults
    } = this.freezer.get();

    if (customerUsersFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "customerUsersFetchResults",
      params: {
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomersCustomerUsersGet(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch customer.");
      }
    });
  }
}

export const CustomerService = new CustomerFreezerService();
export type ICustomerServiceInjectedProps = ReturnType<CustomerFreezerService["getPropsForInjection"]>;
