import {
  FreezerService,
  moment,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional,
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  CustomerQuoteApiFactory,
  CustomerQuote,
  CustomerQuotesSearchCriteria,
  SimplifiedPortalCustomerQuoteSearchResult,
  CustomerQuotesSearchCriteriaQuoteStatusesEnum,
  CustomerQuotesSearchCriteriaDateTypeEnum
} from "$Generated/api";

import {
  ISortState
} from "$Imports/CommonComponents";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  QuoteFreightService
} from "./QuoteFreightFreezerService";

import {
  QuoteZipCodeMileageService
} from "./QuoteZipCodeMileageFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

export const QuoteSearchValidationSchema: SchemaOf<NullableOptional<CustomerQuotesSearchCriteria>> = yup.object({
  accountManagerId: yup.number().nullable().notRequired(),
  businessDevManagerId: yup.number().nullable().notRequired(),
  dateType: yup.mixed<CustomerQuotesSearchCriteriaDateTypeEnum>().notRequired(),
  startDate: yup.date()
    .typeError("Invalid date")
    .notRequired(),
  endDate: yup.date()
    .typeError("Invalid date")
    .notRequired()
    .when("startDate", {
      is: (value?: Date) => !!value,
      then: yup.date().typeError("Invalid date")
        .test("endDate", "${message}", (value: Date | undefined, testContext: any) => {
          const startDate = testContext.parent.startDate;

          if (value && moment(value).isBefore(moment(startDate, 'day'))) {
            return testContext.createError({ message: "To must be after From" });
          }

          return true;
        }),
        otherwise: yup.date().typeError("Invalid date").notRequired()
    }),
  quoteDate: yup.date().nullable().typeError("Invalid date").notRequired(),
  quoteStatuses: yup.mixed<CustomerQuotesSearchCriteriaQuoteStatusesEnum[]>().notRequired(),
  quoteNumber: yup.string().notRequired().allowEmpty(),
  quoteOrPONumber: yup.string().notRequired().allowEmpty(),
  description: yup.string().notRequired().allowEmpty().min(2, "At least 2 characters are required."),
  notes: yup.string().notRequired().allowEmpty().min(2, "At least 2 characters are required."),
  customerId: yup.number().notRequired(),
  customerName: yup.string()
    .notRequired()
    .allowEmpty()
    .min(3, "At least 3 characters are required."),
  sortColumn: yup.string().notRequired().allowEmpty(),
  sortAscending: yup.boolean().notRequired(),
  startIndex: yup.number().notRequired(),
  pageSize: yup.number().notRequired(),
  quoteListType: yup.string().notRequired().allowEmpty(),
  isReviewed: yup.boolean().notRequired(),
  companyId: yup.number().notRequired()
});

const QuoteSearchDateRangeRequiredValidationSchema = QuoteSearchValidationSchema.concat(
  yup.object({
    startDate: yup.date()
      .typeError("Invalid date")
      .required("From is required"),
    endDate: yup.date()
      .typeError("Invalid date")
      .required("To is required")
      .test("endDate", "${message}", (value: Date | undefined, testContext: any) => {
        const startDate = testContext.parent.startDate;

        if (value && moment(value).isBefore(moment(startDate, 'day'))) {
          return testContext.createError({ message: "To must be after From" });
        }

        return true;
      }),
  }));

export interface ICustomerQuotesFreezerState {
  quotesFetchResults: IAjaxState<SimplifiedPortalCustomerQuoteSearchResult>;
  acceptedQuotesFetchResults: IAjaxState<SimplifiedPortalCustomerQuoteSearchResult>;
  quoteFetchResults: IAjaxState<CustomerQuote>;
  completeQuoteResults: IAjaxState<CustomerQuote>;
  searchCriteria: CustomerQuotesSearchCriteria;
  acceptedSearchCriteria: CustomerQuotesSearchCriteria;
  searchValidationErrors: ValidationError | null;
  sortState: ISortState;
}

export const DEFAULT_CUSTOMER_QUOTE_SEARCH: CustomerQuotesSearchCriteria = {
  dateType: "QuoteDate",
  quoteOrPONumber: undefined,
  quoteStatuses: ["Pending"],
  sortColumn: "deliveryDate",
  sortAscending: true,
  startIndex: 0,
  pageSize: 1000
};

export const DEFAULT_CUSTOMER_QUOTES_LIST_SEARCH: CustomerQuotesSearchCriteria = {
  dateType: "QuoteDate",
  quoteOrPONumber: "",
  description: "",
  notes: "",
  quoteStatuses: ["Pending"],
  sortColumn: undefined,
  sortAscending: false,
  startDate: undefined,
  endDate: undefined,
  startIndex: 0,
  pageSize: 1000
};

export const DEFAULT_ACCEPTED_CUSTOMER_QUOTES_SEARCH: CustomerQuotesSearchCriteria = {
  dateType: "QuoteDate",
  quoteOrPONumber: undefined,
  quoteDate: undefined,
  quoteStatuses: ["Accepted"],
  sortColumn: "deliveryDate",
  sortAscending: true,
  startIndex: 0,
  pageSize: 1000
};

const InjectedPropName = "customerQuotesService";

const initialState = {
  quotesFetchResults: managedAjaxUtil.createInitialState(),
  acceptedQuotesFetchResults: managedAjaxUtil.createInitialState(),
  quoteFetchResults: managedAjaxUtil.createInitialState(),
  completeQuoteResults: managedAjaxUtil.createInitialState(),
  searchCriteria: {
    dateType: "QuoteDate",
    quoteOrPONumber: undefined,
    quoteDate: undefined,
    quoteStatuses: ["Pending"],
    sortColumn: "deliveryDate",
    sortAscending: true,
    startIndex: 0,
    pageSize: 1000
  },
  acceptedSearchCriteria: DEFAULT_ACCEPTED_CUSTOMER_QUOTES_SEARCH,
  searchValidationErrors: null,
} as ICustomerQuotesFreezerState;

class CustomerQuotesFreezerService extends FreezerService<ICustomerQuotesFreezerState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  @bind
  public resetAcceptedQuotes() {
    this.freezer.get().set({acceptedQuotesFetchResults: managedAjaxUtil.createInitialState()});
  }

  public async fetchQuote(quoteNumber: string | undefined) {
    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quoteFetchResults",
      params: {
        quoteNumber
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerQuotesGet(params);
      },
      onOk: (data: CustomerQuote) => {
        const customerQuote = data;

        if (customerQuote.shipperZipPostalCode) {
          QuoteZipCodeMileageService.updateShipperZipCode(customerQuote.shipperZipPostalCode);
        }

        if (customerQuote.consigneeZipPostalCode) {
          QuoteZipCodeMileageService.updateConsigneeZipCode(customerQuote.consigneeZipPostalCode);
        }

        // ensure freight totals are calculated when loading an existing quote
        QuoteFreightService.calculateFreightTotalResults(customerQuote.customerQuoteFreights ?? [], customerQuote.equipmentType?.overdimensionalRulesetId);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to fetch quotes.");
      }
    });
  }

  public async fetchQuotes(forceUpdate: boolean = false) {
    const {
      quotesFetchResults,
      searchCriteria
    } = this.freezer.get();

    const validationErrors =await validateSchema(QuoteSearchValidationSchema, searchCriteria.toJS(), {
      abortEarly: false
    });
    if (validationErrors || (quotesFetchResults.hasFetched && !forceUpdate)) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quotesFetchResults",
      params: { 
        body: searchCriteria.toJS()
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerQuotesSearchPost(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch quotes.");
      }
    });
  }

  public async fetchMyQuotes(forceUpdate: boolean = false) {
    const {
      quotesFetchResults,
      searchCriteria
    } = this.freezer.get();

    const criteria = searchCriteria.toJS();
    if (criteria.quoteStatuses?.length === 0) {
      criteria.quoteStatuses = ['Declined', 'Expired', 'Pending', 'Requested'];
    }

    const validationErrors =await validateSchema(QuoteSearchValidationSchema, criteria, {
      abortEarly: false
    });
    if (validationErrors || (quotesFetchResults.hasFetched && !forceUpdate)) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quotesFetchResults",
      params: { 
        body: criteria
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerQuotesSearchPost(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch quotes.");
      }
    });
  }

  public async fetchAcceptedQuotes(forceUpdate: boolean = false) {
    const {
      acceptedQuotesFetchResults,
      acceptedSearchCriteria
    } = this.freezer.get();

    const validationErrors =await validateSchema(QuoteSearchValidationSchema, acceptedSearchCriteria.toJS(), {
      abortEarly: false
    });
    if (validationErrors || (acceptedQuotesFetchResults.hasFetched && !forceUpdate)) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "acceptedQuotesFetchResults",
      params: { 
        body: acceptedSearchCriteria.toJS()
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerQuotesSearchPost(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch quotes.");
      }
    });
  }

  public completeQuote(quoteNumber: number): Promise<CustomerQuote | void> {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "completeQuoteResults",
      params: {
        quoteNumber: quoteNumber
      },
      onExecute: (apiOptions, params) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerQuotesQuoteNumberCompletedPost(params);
      },
      onOk: () => {
        ErrorService.pushErrorMessage("Quote completed.", "successful");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to complete quote.");
      }
    });
  }

  public onSearchModelChanged(searchModel: Partial<CustomerQuotesSearchCriteria>, acceptedOnly: boolean = false) {
    !acceptedOnly ? this.freezer.get().searchCriteria.set(searchModel) : this.freezer.get().acceptedSearchCriteria.set(searchModel);
  }
}

export const CustomerQuoteService = new CustomerQuotesFreezerService();
export type ICustomerQuoteServiceInjectedProps = ReturnType<CustomerQuotesFreezerService["getPropsForInjection"]>;